@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?cucjey');
  src:  url('fonts/icomoon.eot?cucjey#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?cucjey') format('truetype'),
    url('fonts/icomoon.woff?cucjey') format('woff'),
    url('fonts/icomoon.svg?cucjey#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-key:before {
  content: "\e950";
}
.icon-sell2:before {
  content: "\e94d";
}
.icon-sell_all2:before {
  content: "\e94e";
}
.icon-buy2:before {
  content: "\e94f";
}
.icon-thunder:before {
  content: "\e949";
}
.icon-sell_all:before {
  content: "\e94b";
}
.icon-sell:before {
  content: "\e94a";
}
.icon-buy:before {
  content: "\e94c";
}
.icon-Transfer:before {
  content: "\e948";
}
.icon-security:before {
  content: "\e947";
}
.icon-tick:before {
  content: "\e90f";
}
.icon-Whats-new:before {
  content: "\e918";
}
.icon-warning .path1:before {
  content: "\e93d";
  color: rgb(239, 168, 167);
}
.icon-warning .path2:before {
  content: "\e93e";
  margin-left: -1.0361328125em;
  color: rgb(255, 255, 255);
}
.icon-warning .path3:before {
  content: "\e93f";
  margin-left: -1.0361328125em;
  color: rgb(255, 255, 255);
}
.icon-warning .path4:before {
  content: "\e940";
  margin-left: -1.0361328125em;
  color: rgb(102, 102, 102);
}
.icon-Icon-Clock:before {
  content: "\e941";
}
.icon-Computer-User:before {
  content: "\e946";
}
.icon-error3:before {
  content: "\e92a";
}
.icon-stamp:before {
  content: "\e942";
}
.icon-Notifications:before {
  content: "\e943";
}
.icon-Maintain:before {
  content: "\e944";
}
.icon-Logoff:before {
  content: "\e945";
}
.icon-enquiry:before {
  content: "\e901";
}
.icon-Bar-Chart-2:before {
  content: "\e900";
}
.icon-Chevron-Left:before {
  content: "\e902";
}
.icon-Chevron-Right:before {
  content: "\e903";
}
.icon-Chevron-Up:before {
  content: "\e904";
}
.icon-Chevron-Down:before {
  content: "\e905";
}
.icon-Arrow-Left:before {
  content: "\e906";
}
.icon-Arrow-Right:before {
  content: "\e907";
}
.icon-Arrow-Up:before {
  content: "\e908";
}
.icon-Arrow-Down:before {
  content: "\e909";
}
.icon-Triangle-Up:before {
  content: "\e90a";
}
.icon-Triangle-Down:before {
  content: "\e90b";
}
.icon-Triangle-Unsorted:before {
  content: "\e90c";
}
.icon-Information:before {
  content: "\e90d";
}
.icon-Approved:before {
  content: "\e90e";
}
.icon-Help:before {
  content: "\e910";
}
.icon-Plus:before {
  content: "\e911";
}
.icon-Minus:before {
  content: "\e912";
}
.icon-Close:before {
  content: "\e913";
}
.icon-Print:before {
  content: "\e914";
}
.icon-Download:before {
  content: "\e915";
}
.icon-Edit:before {
  content: "\e916";
}
.icon-Delete:before {
  content: "\e917";
}
.icon-Calculate:before {
  content: "\e919";
}
.icon-Buy:before {
  content: "\e91a";
}
.icon-Sell:before {
  content: "\e91b";
}
.icon-Quick-Cash:before {
  content: "\e91c";
}
.icon-Redeem:before {
  content: "\e91d";
}
.icon-Insurance:before {
  content: "\e91e";
}
.icon-Cash-Management:before {
  content: "\e91f";
}
.icon-Loan-Facility:before {
  content: "\e920";
}
.icon-Investments:before {
  content: "\e921";
}
.icon-MPF-and-Payroll-Services:before {
  content: "\e922";
}
.icon-Credit-Card:before {
  content: "\e923";
}
.icon-Management-Control:before {
  content: "\e924";
}
.icon-Authorisations:before {
  content: "\e925";
}
.icon-Gift:before {
  content: "\e926";
}
.icon-Like:before {
  content: "\e927";
}
.icon-Unlike:before {
  content: "\e928";
}
.icon-View:before {
  content: "\e929";
}
.icon-External-Link:before {
  content: "\e92b";
}
.icon-Settings:before {
  content: "\e92c";
}
.icon-Subscribe:before {
  content: "\e92d";
}
.icon-Switch:before {
  content: "\e92e";
}
.icon-Clock:before {
  content: "\e92f";
}
.icon-Search:before {
  content: "\e930";
}
.icon-Select-All:before {
  content: "\e931";
}
.icon-Select-Again:before {
  content: "\e932";
}
.icon-Pie-Chart:before {
  content: "\e933";
}
.icon-Line-Chart:before {
  content: "\e934";
}
.icon-Bar-Chart:before {
  content: "\e935";
}
.icon-Home:before {
  content: "\e936";
}
.icon-E-Services:before {
  content: "\e937";
}
.icon-Customer-Services:before {
  content: "\e938";
}
.icon-Envelop:before {
  content: "\e939";
}
.icon-Office:before {
  content: "\e93a";
}
.icon-User-Tie:before {
  content: "\e93b";
}
.icon-Phone:before {
  content: "\e93c";
}
